import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as dat from 'dat.gui'
/* import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js'
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js'
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass.js' */
import { LightProbeGenerator } from 'three/examples/jsm/lights/LightProbeGenerator.js'
import { gsap } from 'gsap'
import { DoubleSide, HemisphereLight } from 'three'
import { sRGBEncoding } from 'three'

console.clear()

/**
 * Loaders
 */

let sceneReady = false
 const loadingBarElement = document.querySelector('.loading-bar')
 const loadingManager = new THREE.LoadingManager(
   // Loaded
   function(){
    

      // Wait a little
      window.setTimeout(() =>
      {
          // Animate overlay
          gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

          // Update loadingBarElement
          loadingBarElement.classList.add('ended')
          loadingBarElement.style.transform = ''
      }, 500)

      window.setTimeout(() =>
      {
          sceneReady = true;
      }, 3000)
   },
   // Progress
   function(itemUrl, itemsLoaded, itemsTotal){
     const progressRatio = itemsLoaded / itemsTotal;
     loadingBarElement.style.transform = `scaleX(${progressRatio})`
     console.log(progressRatio)
   }
 )
 const gltfLoader = new GLTFLoader(loadingManager)
 const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)
 const textureLoader = new THREE.TextureLoader()

/**
 * Base
 */
// Debug
const debugObject = {
  bodyworkColor: '#ffffff',
  bodyworkRoughness: 0.08,
  light1Color: '#ffffff',
  light2Color: '#ffffff'
}
const gui = new dat.GUI()
gui.width = 400
var lightingFolder = gui.addFolder('Lights');

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Overlay
const overlayGeometry = new THREE.PlaneBufferGeometry(2,2,1,1)
const overlayMaterial = new THREE.ShaderMaterial({
  transparent: true,
  uniforms: {
    uAlpha: { value: 1 }
  },
  vertexShader: `
  void main(){
    gl_Position = vec4(position, 1.0);
  }
  `,
  fragmentShader:`
  uniform float uAlpha;
  void main() {
    gl_FragColor = vec4( 1.0, 1.0, 1.0, uAlpha);
  }
  `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)


/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            child.material.envMapIntensity = 5
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}

/**
 * Environment map
 */
let lightProbe, lightProbe2, lightProbe3
const environmentMap1 = cubeTextureLoader.load([
    '/textures/environmentMaps/1/px.png',
    '/textures/environmentMaps/1/nx.png',
    '/textures/environmentMaps/1/py.png',
    '/textures/environmentMaps/1/ny.png',
    '/textures/environmentMaps/1/pz.png',
    '/textures/environmentMaps/1/nz.png'
]/* , function(cubeTexture){
  lightProbe = new THREE.LightProbe()
lightProbe.copy( LightProbeGenerator.fromCubeTexture( cubeTexture ))
lightProbe.intensity = 2
//scene.add( lightProbe );
lightingFolder.add(lightProbe, 'intensity').min(0).max(10).step(0.5).name('lightPrope intensity')
} */)

const environmentMap2 = cubeTextureLoader.load([
    '/textures/environmentMaps/2/px.png',
    '/textures/environmentMaps/2/nx.png',
    '/textures/environmentMaps/2/py.png',
    '/textures/environmentMaps/2/ny.png',
    '/textures/environmentMaps/2/pz.png',
    '/textures/environmentMaps/2/nz.png'
]/* , function(cubeTexture){
  lightProbe2 = new THREE.LightProbe()
lightProbe2.copy( LightProbeGenerator.fromCubeTexture( cubeTexture ))
lightProbe2.intensity = 2
//scene.add( lightProbe2 );
lightingFolder.add(lightProbe2, 'intensity').min(0).max(10).step(0.5).name('lightProbe2 intensity')
} */)

const environmentMap3 = cubeTextureLoader.load([
  '/textures/environmentMaps/3/px.png',
  '/textures/environmentMaps/3/nx.png',
  '/textures/environmentMaps/3/py.png',
  '/textures/environmentMaps/3/ny.png',
  '/textures/environmentMaps/3/pz.png',
  '/textures/environmentMaps/3/nz.png'
]/* , function(cubeTexture){
lightProbe3 = new THREE.LightProbe()
lightProbe3.copy( LightProbeGenerator.fromCubeTexture( cubeTexture ))
lightProbe3.intensity = 2
//scene.add( lightProbe2 );
lightingFolder.add(lightProbe3, 'intensity').min(0).max(10).step(0.5).name('lightProbe3 intensity')
} */)


environmentMap1.encoding = THREE.sRGBEncoding
environmentMap2.encoding = THREE.sRGBEncoding
environmentMap3.encoding = THREE.sRGBEncoding


scene.environment = environmentMap1


// bodywork material
const bodyworkMaterial = new THREE.MeshStandardMaterial({ 
  color: debugObject.bodyworkColor,
  side: DoubleSide,
  roughness: debugObject.bodyworkRoughness,
  encoding: THREE.sRGBEncoding,
  castShadow: true

})

const glassMaterial = new THREE.MeshPhysicalMaterial({
  //color: params.color,
  metalness: 0.6,
  roughness: 0.1,
  /* reflectivity: 0.6, */
  reflectivity: 0.4, 
  //alphaMap: texture,
  //envMap: environmentMap,
  //envMapIntensity: 0.8,
  transmission: 0.95, // use material.transmission for glass materials
  //specularIntensity: 0.9,
  opacity: 0.7,
  
  side: THREE.DoubleSide,
  transparent: true
})

const redLightMaterial = new THREE.MeshPhysicalMaterial({
  color: 0xff0000,
  opacity:0.65,
  transmission: 0.14,
  //metalness: 0.6,
  roughness:0.1,
  reflectivity:0.6,
  transparent: true,
  side: THREE.DoubleSide,
})

const orangeLightMaterial = new THREE.MeshPhysicalMaterial({
  color: 0xffa500,
  opacity:0.65,
  transmission: 0.14,
  metalness: 0.6,
  roughness:0.1,
  reflectivity:0.6,
  transparent: true,
  side: THREE.DoubleSide,
})

const chromeMaterial = new THREE.MeshPhysicalMaterial({
  metalness: 1.0,
  roughness:0.1
})
let worldSphere1, worldSphere2, worldSphere3

worldSphere1 = textureLoader.load('/textures/world1.jpg')
worldSphere1.flipY = false
worldSphere1.encoding = sRGBEncoding
worldSphere1.offset.set(0, 0.02);


const worldMaterial = new THREE.MeshBasicMaterial({ 
  map: worldSphere1,
  side: THREE.BackSide,
  //side: THREE.DoubleSide,
  /* receiveShadow: true,
  castShadow: true, */
  needsUpdate: true,
  wireframe: true
})
gui.add(worldMaterial, 'wireframe').name('environment wireframe')

const shadowCatcherMaterial = new THREE.ShadowMaterial()
shadowCatcherMaterial.opacity = 0.5
gui.add(shadowCatcherMaterial, 'opacity').min(0).max(1).step(0.05).name('shadowcatcher')
/**
 * Models
 */
var wheelFD, wheelFP, wheelRD, wheelRP, environmentSphere

const shadowCatcherGeometry = new THREE.PlaneGeometry(20, 20)
shadowCatcherGeometry.rotateX( - Math.PI / 2 )
const shadowCatcherPlane = new THREE.Mesh( shadowCatcherGeometry, shadowCatcherMaterial );
shadowCatcherPlane.position.y = -0.59
shadowCatcherPlane.receiveShadow = true;
scene.add( shadowCatcherPlane );


gui.add(shadowCatcherPlane.position,'y').min(-1).max(1).step(0.01).name('shdowcatcherY')


/* gltfLoader.load(
  '/models/car/dome.glb',
  (gltf) =>
  {
    environmentSphere = gltf.scene
    environmentSphere.material = worldMaterial
    //environmentSphere.receiveShadow = true
    scene.add(environmentSphere)
    environmentSphere.scale.set(0.3, 0.3, 0.3)
      environmentSphere.position.set(0,-0.6,0)
      updateAllMaterials()
  }) */

 /*  gltfLoader.load(
    '/models/car/Ranger_doublecab_and_dome.glb',
    (gltf) =>
    {
      gltf.scene.traverse(function(child){
         console.log(child.name)
        if (child.name === 'Windows_Tinted_0' || child.name === 'Windows_Tinted_1') {
          child.material = glassMaterial
        }

        if (child.name === 'Lens_Clear_1' || child.name === 'Lens_Clear_2' || child.name === 'Lens_Clear_3' || child.name === 'Lens_Clear_4' || child.name === 'Lens_Clear_5' || child.name === 'Lens_Clear_6' || child.name === 'Lens_Clear_7' || child.name === 'Lens_Clear_8' || child.name === 'Lens_Clear_9' || child.name === 'Lens_Clear_10' || child.name === 'Lens_Clear_11' || child.name === 'Lens_Clear_12' || child.name === 'Lens_Clear_13' || child.name === 'Lens_Clear_14' || child.name === 'Lens_Clear_15' || child.name === 'Lens_Clear_16' || child.name === 'Lens_Clear_17' || child.name === 'Lens_Clear_18' || child.name === 'Lens_Clear_6001' || child.name === 'Lens_Clear_7001' ) {
          child.material = glassMaterial
        }

        if (child.name === 'polySurface1' || child.name === 'polySurface2'  || child.name === 'RT_Chrome_Grain' || child.name === 'RT_Badging_chrome_0' || child.name === 'RT_Chrome_0'  || child.name === 'RT_Chrome_1'  || child.name === 'RT_Chrome_2'  || child.name === 'Tail_Light_X_Chrome' ){
          child.material = chromeMaterial
        }

        if (child.name === 'Lens_Red1001' || child.name === 'Lens_Red1'  || child.name === 'Partten_Lens_Red' || child.name === 'Partten_Lens_Red001'  || child.name === 'polySurface3434001'  || child.name === 'polySurface3434'){
          child.material = redLightMaterial
        }

        if (child.name === 'RT_Lens_Orange_1_LensBack' || child.name === 'RT_Lens_Orange_1_LensFront'  || child.name === 'RT_Lens_Orange_2_LensFront'){
          child.material = orangeLightMaterial
        } 

        if (child.name === 'polySurface3447') {
          var bodywork = child
          bodywork.material = bodyworkMaterial
          bodywork.castShadow = true
        }

       if (child.name === 'environment_dome') {
          environmentSphere = child
          environmentSphere.material = worldMaterial
          environmentSphere.receiveShadow = true
        }

      
      });

      //gltf.scene.scale.set(0.6, 0.6, 0.6)
      gltf.scene.position.set(0,-0.6,0)

      scene.add(gltf.scene)

      updateAllMaterials()
    }
) */

gltfLoader.load(
  '/models/car/Ranger_doublecab_2.glb',
  (gltf) =>
  {
    gltf.scene.traverse(function(child){
       console.log(child.name)
      if (child.name === 'Windows_Tinted_0001' || child.name === 'Windows_Tinted_1001') {
        child.material = glassMaterial
      }

      if (child.name === 'Lens_Clear_1001' || child.name === 'Lens_Clear_2001' || child.name === 'Lens_Clear_3001' || child.name === 'Lens_Clear_4001' || child.name === 'Lens_Clear_5001' || child.name === 'Lens_Clear_6001' || child.name === 'Lens_Clear_7001' || child.name === 'Lens_Clear_8001' || child.name === 'Lens_Clear_9001' || child.name === 'Lens_Clear_10001' || child.name === 'Lens_Clear_11001' || child.name === 'Lens_Clear_12001' || child.name === 'Lens_Clear_13001' || child.name === 'Lens_Clear_14001' || child.name === 'Lens_Clear_15001' || child.name === 'Lens_Clear_16001' || child.name === 'Lens_Clear_17001' || child.name === 'Lens_Clear_18001' || child.name === 'Lens_Clear_15.001_primitive0' || child.name === 'Lens_Clear_15.001_primitive1' || child.name === 'Mesh112'|| child.name === 'Mesh112_1' || child.name === 'Mesh123' || child.name === 'Lens_Clear_6003' || child.name === 'Lens_Clear_7003' || child.name === 'Lens_Clear_6002' || child.name === 'Lens_Clear_7002') {
        child.material = glassMaterial
      }

      if (child.name === 'polySurface1' || child.name === 'polySurface2'  || child.name === 'RT_Chrome_Grain' || child.name === 'RT_Badging_chrome_0' || child.name === 'RT_Chrome_0'  || child.name === 'RT_Chrome_1'  || child.name === 'RT_Chrome_2'  || child.name === 'Tail_Light_X_Chrome' || child.name === 'Chrome001'){
        child.material = chromeMaterial
      }

      if (child.name === 'Lens_Red1003' || child.name === 'Lens_Red1002'  || child.name === 'Partten_Lens_Red003' || child.name === 'Partten_Lens_Red002'  || child.name === 'polySurface3434002'  || child.name === 'polySurface3434003'){
        child.material = redLightMaterial
      }

      if (child.name === 'RT_Lens_Orange_1_LensBack' || child.name === 'RT_Lens_Orange_1_LensFront'  || child.name === 'RT_Lens_Orange_2_LensFront'){
        child.material = orangeLightMaterial
      } 

      if (child.name === 'Paint') {
        var bodywork = child
        bodywork.material = bodyworkMaterial
        bodywork.castShadow = true
      }

     if (child.name === 'environment_dome') {
        environmentSphere = child
        environmentSphere.material = worldMaterial
        environmentSphere.receiveShadow = true
      }

    
    });

    //gltf.scene.scale.set(0.6, 0.6, 0.6)
    gltf.scene.position.set(0,-0.6,0)

    scene.add(gltf.scene)

    updateAllMaterials()
  }
)
/**
 * Lights
 */
// probe


const hemisphereLight = new THREE.HemisphereLight(0xffffff, 1.8)
hemisphereLight.intensity = 2.8
scene.add(hemisphereLight)
lightingFolder.add(hemisphereLight, 'intensity').min(0).max(30).step(0.2).name('hemisphere_intensity') 

/* const ambientLight = new THREE.AmbientLight(0xffffff, 1.8)
ambientLight.intensity = 2.8
scene.add(ambientLight)
lightingFolder.add(ambientLight, 'intensity').min(0).max(30).step(0.2).name('ambient_intensity') */

const directionalLight = new THREE.DirectionalLight('#b9d4e8', 0.8)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(512, 512)
directionalLight.shadow.camera.far = 26
directionalLight.shadow.normalBias = 0.03
directionalLight.position.set(7, 9, -5.5)

scene.add(directionalLight)

const lightHelper = new THREE.DirectionalLightHelper( directionalLight, 5 );
//scene.add( lightHelper )


lightingFolder.add(directionalLight, 'intensity').min(0).max(30).step(0.1).name('light_1_intensity')
lightingFolder.add(directionalLight.position, 'x').min(-10).max(10).step(0.1).name('light_1_position_x')
lightingFolder.add(directionalLight.position, 'y').min(0).max(10).step(0.1).name('light_1_position_y')
lightingFolder.add(directionalLight.position, 'z').min(-10).max(10).step(0.1) .name('light_1_position_z')
lightingFolder.add(directionalLight, 'castShadow').name('light_1_shadow')
lightingFolder.addColor(debugObject, 'light1Color').name('light_1_color').onChange(() => {
  directionalLight.color.set(debugObject.light1Color)
 })

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
//const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
const camera = new THREE.PerspectiveCamera(25, sizes.width / sizes.height, 0.1, 100)
camera.position.set(10, 0.9, -14)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.minDistance = 4.6
controls.maxDistance = 18
//controls.minDistance = 4.4
//controls.maxDistance = 8
controls.maxPolarAngle = Math.PI / 2 - 0.1
controls.target= new THREE.Vector3(0,0,0)

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    // THE FOLLOWING LINE IS REQUIRED TO SAVE AN IMAGE OF THE CANVAS ELEMENT - OTHERWISE IT WILL RETURN A BLANK IMAGE
    preserveDrawingBuffer: true 
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.LinearToneMapping
//renderer.toneMappingExposure = 1.5
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
debugObject.clearColor = '#216cc5'
renderer.setClearColor(debugObject.clearColor) 

// GUI CHANGES
/* const axesHelper = new THREE.AxesHelper( 20 );
scene.add( axesHelper ); */

//debugObject.clearColor = '#ffffff'
gui.addColor(debugObject, 'clearColor').name('backgroundColour').onChange(() => {
  
  renderer.setClearColor(debugObject.clearColor)
}) 


/* 
gui.addColor(debugObject, 'bodyworkColor').name('bodyworkColour').onChange(() => {
 bodyworkMaterial.color.set(debugObject.bodyworkColor)
})*/

gui.add(debugObject, 'bodyworkRoughness').min(0).max(1).step(0.01).name('bodyworkRoughness').onChange(() => {
  bodyworkMaterial.roughness = debugObject.bodyworkRoughness
 })

// -------- BUTTONS -------------//

// BODYWORK COLOURS
const blackButton = document.querySelector('#black')
const greyButton = document.querySelector('#grey')
const whiteButton = document.querySelector('#white')


blackButton.addEventListener('click', function(){
  debugObject.bodyworkColor = '#000000'
  bodyworkMaterial.color.set(debugObject.bodyworkColor)
})

greyButton.addEventListener('click', function(){
  debugObject.bodyworkColor = '#26272d'
  bodyworkMaterial.color.set(debugObject.bodyworkColor)
})

whiteButton.addEventListener('click', function(){
  debugObject.bodyworkColor = '#ffffff'
  bodyworkMaterial.color.set(debugObject.bodyworkColor)
})

// ENVIRONMENTS
const env1Button = document.querySelector('#env1')
const env2Button = document.querySelector('#env2')
const env3Button = document.querySelector('#env3')


env1Button.addEventListener('click', function(){
  worldSphere1 = textureLoader.load('/textures/world1.jpg')
  worldSphere1.flipY = false
  worldSphere1.encoding = sRGBEncoding
  worldSphere1.offset.set(0, 0.016);
  worldMaterial.map = worldSphere1
  scene.environment = environmentMap1
  directionalLight.position.set(7, 9, -5.5)
  directionalLight.color.set('#b9d4e8')
})

env2Button.addEventListener('click', function(){
  worldSphere2 = textureLoader.load('/textures/world2.jpg')
  worldSphere2.flipY = false
  worldSphere2.encoding = sRGBEncoding
  worldSphere2.offset.set(0, 0.016);
  worldMaterial.map = worldSphere2
  scene.environment = environmentMap2
  directionalLight.position.set(2.5, 3.4, -1.9)
  directionalLight.color.set('#b9d4e8')
})

env3Button.addEventListener('click', function(){
  worldSphere3 = textureLoader.load('/textures/world3.jpg')
  worldSphere3.flipY = false
  worldSphere3.encoding = sRGBEncoding
  worldSphere3.offset.set(0, 0.016);
  worldMaterial.map = worldSphere3
  scene.environment = environmentMap3
  directionalLight.position.set(2.5, 0.5, -1.7)
  directionalLight.color.set('#fccc88')
/*   directionalLight.intensity =2.5 */

  //hemisphereLight.intensity = 1.2
}) 

// PHOTO - SAVE AN IMAGE OF THE CURRENT VIEW
const photoButton = document.querySelector('#photo')
photoButton.addEventListener('click', function(){
  // PARAMETERS BELOW ARE FOR IMAGE TYPE AND IMAGE QUALITY (0 - 1.0)
  const dataURL = canvas.toDataURL('image/jpeg', 0.75);
  var link = document.createElement("a");
			link.setAttribute('download', "configurator_pic_test.jpeg")
			link.setAttribute('href', dataURL);
			link.click();
})

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()
    
    // Render
    renderer.render(scene, camera)
    //effectComposer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()